<template>
  <Layout>
    <PageHeader title="Edit Invoice" />
    <div v-if="results" class="row p-4 flex justify-center">
      <div class="col-md-10 p-4 bg-light">
        <div class="form-group">
          <label for="Custommer-number">Customer Internal Number</label>
          <input type="text" placeholder="enter custommer internal Number" class="form-control"
            v-model="results.clientInternalNumber" @change="updateCIN" />
        </div>

        <!-- Dynamic inputs -->
        <div class="form-group">
          <label for="Customer Type"> Select Customer Type </label>
          <select style="background-color: #d3d3d3;" disabled v-model="clienttypeid" class="form-control"
            name="customer type">
            <option selected :value="clienttypeid"> {{ clienttypeid == 1 ? "Oonpay customer" : "Non-Oonpay customer "}}
            </option>
          </select>
        </div>

        <div v-if="clienttypeid == 2">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="First Name">Customer First Name</label>
                <input style="background-color: #d3d3d3;" disabled type="name" placeholder="enter first name"
                  class="form-control" v-model="clientfirstname" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="Last Name">Customer Last Name</label>
                <input style="background-color: #d3d3d3;" disabled type="name" placeholder="enter last name"
                  class="form-control" v-model="clientlastname" />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="customer email">Customer Email</label>
                <input style="background-color: #d3d3d3;" disabled type="name" placeholder="enter email address"
                  class="form-control" v-model="clientemail" />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="form-group">
            <label for="">Customer Phone</label>
            <input style="background-color: #d3d3d3;" disabled :value="invoice.clientPhoneNumber" type="text"
              placeholder="Enter phone number" class="form-control" @change="updateClientNumber" />
          </div>
        </div>

        <!-- Multi select -->
        <div class="form-group">
          <label for="Service">Service(s) Offered</label>
          <multiselect v-model="value" :options="options" :multiple="true" :clear-on-select="false"
            :hide-selected="true" placeholder="Select service(s)" label="fullName" track-by="fullName"
            @select="optionSelected"></multiselect>

          <div v-for="(service, key) in value" :key="key"
            class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3">
            <div class="d-flex justify-content-between">
              <span> {{ service.fullName }}</span>
              <span @click="deleteUpdatedService(key)" class="text-oonpaysecondary"><i class="fa fa-trash"
                  aria-hidden="true"></i></span>
            </div>
          </div>

          <div v-if="results.servicesOffered">
            <div v-for="(medi, key) in results.servicesOffered" :key="key"
              class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3">
              <div class="d-flex justify-content-between">
                <span>{{ medi.serviceFullName || medi }}</span>
                <span @click="DeleteMed(key)" class="text-oonpaysecondary"><i class="fa fa-trash"
                    aria-hidden="true"></i></span>
              </div>
            </div>
          </div>
        </div>

        <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="Start Date"> Services Start Data</label>
              <input type="date" name="start data" class="form-control" placeholder="choose start date"
                v-model="servicestartdate" @change="updateSStartDate" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="Start Date"> Services End Data</label>
              <input type="date" name="end data" class="form-control" placeholder="choose end date"
                v-model="serviceenddate" @change="updateSEndDate" />
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="Total Cost">Total Cost </label>
              <input type="number" class="form-control" placeholder="enter total cost" :value="invoice.cost"
                @change="UpdateTotalCost" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="payment due">Select Payment Due Date</label>
              <input type="date" name="data" class="form-control" placeholder="enter payment due date" v-model="duedate"
                @change="UpdateDueDate" />
            </div>
          </div>
        </div>

        <div class="form-group my-4">
          <div class="custom-file">
            <input type="file" @change="uploadFile" class="custom-file-input" multiple ref="files" />
            <label class="custom-file-label" for="upload docs">upload documents here</label>
            <div v-for="(file, key) in Documents" :key="key"
              class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3">
              <div class="d-flex justify-content-between">
                <span> {{ file.name }}</span>
                <span @click="deleteUpdatedDoc(key)" class="text-oonpaysecondary"><i class="fa fa-trash"
                    aria-hidden="true"></i></span>
              </div>
            </div>
            <div v-if="results.documents">
              <div v-for="(medi, key) in results.documents" :key="key"
                class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3">
                <div class="d-flex justify-content-between">
                  <span>{{
                    medi.fullName.length >= 30
                    ? medi.fullName.substring(0, 30)
                    : medi.fullName
                    }}</span>
                  <span @click="deleteDoc(key)" class="text-oonpaysecondary"><i class="fa fa-trash"
                      aria-hidden="true"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="Customer Phone Number"></label>
          <div class="form-row">
            <div class="col-sm-3">
              <select v-model="selected_benefactor_callCode" class="form-control" name="countries">
                <option v-for="country in countries_code" :key="country.index" :value="country">
                  {{ country.callCode }}
                </option>
              </select>
            </div>
            <div class="col-sm-6">
              <input type="number" v-model="benefactor" class="form-control" placeholder="enter benefactory phone" />
            </div>
            <div class="col-md-3">
              <button @click.prevent="getBenefactor" class="btn btn-primary">
                Add
              </button>
            </div>
          </div>
          <div v-if="results && results.benefactors" class="row flex mt-3 justify-center">
            <div class="col-md-6">
              <ul v-for="(num, key) in results.benefactors" :key="key" class="list-group">
                <li class="list-group-item bg-oonpayprimary  d-flex justify-between  ">
                  <span>
                    {{ num.clientFullName }} ({{ num.clientPhoneNumber }})</span>
                  <span @click="deleteNumber(key, num.id)"><span class="iconify"
                      data-icon="fluent:delete-dismiss-28-filled" data-inline="false"></span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <button @click.prevent="handleSubmitInvoice" class="btn my-4 btn-primary btn-sm btn-block">
          <b-spinner v-if="loading" type="grow"></b-spinner>
          <span v-else>Update Invoice</span>
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Multiselect from "vue-multiselect";
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import moment from "moment";
export default {
  components: { Layout, Multiselect, PageHeader },
  data() {
    return {
      clientinternalnumber: "",
      clientfirstname: "",
      clientlastname: "",
      clientemail: "",
      clienttypeid: 1,
      clientcountryId: "",
      clientephone: "",

      servicestartdate: "",
      serviceenddate: "",
      totalcost: "",
      duedate: "",
      benefactors: [],
      Documents: [],
      ServicesOffered: [],
      selected_callCode: "",
      confirm_otp: null,
      show_confirm: 1,
      options: [],
      value: [],
      clientcountrycode: "",

      phonenumbers: [],
      clientphonenum: "",
      countries_code: [],
      phone: "",
      files: null,
      benefactor: "",
      benefactorsGuid: [],
      selected_benefactor_callCode: "",

      selected_code: null,
      customerphonenumber: null,

      customer_type: "",
      loading: false,

      merchantguid: window.localStorage.getItem("merchantGUID"),
      employeeGUID: window.localStorage.getItem("employeeGUID"),

      invoices: [],
      invoice: {},
      invoice_guid: "",
      results: []
    };
  },
  methods: {
    updateCIN(e) {
      this.$store.dispatch("invoice/UpdateCIN", e.target.value);
    },
    updateSStartDate(e) {
      this.$store.dispatch("invoice/updateStartDate", e.target.value);
    },
    updateSEndDate(e) {
      this.$store.dispatch("invoice/updateSEndDate", e.target.value);
    },
    UpdateDueDate(e) {
      this.$store.dispatch("invoice/UpdateDueDate", e.target.value);
    },
    UpdateTotalCost(e) {
      this.$store.dispatch("invoice/UpdateTotalCost", e.target.value);
    },
    updateClientNumber(e) {
      this.$store.dispatch("invoice/UpdateClientNum", e.target.value);
    },
    //   Get All Invoices
    getAllInvoices() {
      this.$store.dispatch("invoice/getAllInvoice").then((response) => {
        // console.log(response.data);
        this.invoices = response.data;
        this.invoices.forEach((invoice) => {
          if (invoice.id == this.$route.params.id) {
            this.invoice = invoice;
            this.invoice_guid = invoice.guid;
            this.duedate = moment(invoice.dueDate).format("YYYY-MM-DD");
            this.GetInvoiceRequiredData();
          }
        });
      });
    },

    // Get Invoice Required data
    GetInvoiceRequiredData() {
      const data = {
        id: this.merchantguid,
        guid: this.invoice_guid
      };
      this.$store
        .dispatch("invoice/GetInvoiceRequiredData", data)
        .then(({ data }) => {
          this.options = data.item2;
          this.results = data.item3;
          this.clientfirstname = data.item3.clientFirstName;
          this.clientlastname = data.item3.clientLastName
          this.clientemail = data.item3.clientEmail
          this.clientphonenum = data.item3.clientPhoneNumber
          this.clienttypeid = data.item3.clientTypeId
          this.serviceenddate = moment(this.results.serviceEndDate).format(
            "YYYY-MM-DD"
          );
          this.servicestartdate = moment(this.results.serviceStartDate).format(
            "YYYY-MM-DD"
          );
         
          this.countries_code = data.item1;
        });
    },

    // Benefactor
    getBenefactor() {
      let newPhone = this.benefactor;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var benefactornumber = newPhone.substring(1);
      }else{
        benefactornumber = this.benefactor;
      }
      let main_number = `${this.selected_benefactor_callCode.callCode}${benefactornumber}`;
      const data = {
        phonenumber: main_number
      };
      this.$store
        .dispatch("invoice/GetBenefactor", data)
        .then((response) => {
          if (response.status == 200) {
            this.results.benefactors.push({
              id: response.data.id,
              clientPhoneNumber: main_number,
              clientFullName:
                response.data.firstName + " " + response.data.lastName,
              clientGUID: response.data.guid
            });

            this.benefactor = "";
          } else {
            this.$toasted.error("Sorry Your number is not on Oonpay", {
              duration: 5000
            });
          }
        })
        .catch(() => {
          this.$toasted.error("Your number is not on Oonpay", {
            duration: 5000
          });
        });
    },

    deleteNumber(key) {
      this.results.benefactors.splice(key, 1);
    },

    deleteDoc(key) {
      this.results.documents.splice(key, 1);
    },
    DeleteMed(key) {
      this.results.servicesOffered.splice(key, 1);
    },

    deleteUpdatedDoc(key) {
      this.Documents.splice(key, 1);
    },
    deleteUpdatedService(key) {
      this.value.splice(key, 1);
    },

    uploadFile(e) {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.Documents.push(this.$refs.files.files[i]);
      }
    },

    handleSubmitInvoice() {
      const services = this.value.map((item) => {
        const container = {
          serviceid: item.id
        };

        return container;
      });

      //get benefactor numbers
      let benefactor = this.results.benefactors.map((item) => {
        return item;
      });

      const formData = new FormData();
      formData.append("clienttypeid", this.clienttypeid);
      formData.append(
        "clientinternalnumber",
        this.$store.state.invoice.customer_internal_num ||
          this.results.clientInternalNumber
      );
      formData.append(
        "ClientPhoneNumber",
        this.$store.state.invoice.client_num || this.invoice.clientPhoneNumber
      ),
        formData.append("clientfirstname", this.clientfirstname);
      formData.append("clientlastname", this.clientlastname);
      formData.append("clientemail", this.clientemail);
        formData.append("timeStamp", JSON.stringify(this.results.timeStamp));
      formData.append("GUID", this.invoice.guid);
      formData.append("EmployeeGUID", this.employeeGUID);
      formData.append("ServiceStartDate", this.servicestartdate);
      formData.append("ServiceEndDate", this.serviceenddate);
      formData.append(
        "TotalCost",
        this.$store.state.invoice.total_cost || this.invoice.totalCost
      );
      formData.append("DueDate", this.duedate);
      formData.append("Benefactors", JSON.stringify(benefactor));
      formData.append(
        "ServicesOffered",
        services
          ? JSON.stringify(this.results.servicesOffered.concat(services))
          : JSON.stringify(this.results.servicesOffered)
      );
      formData.append("Documents", JSON.stringify(this.results.documents));

      var self = this;
      for (let i = 0; i < this.Documents.length; i++) {
        let file = self.Documents[i];
        formData.append("New File", file);
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      this.loading = true;
      this.$store
        .dispatch("invoice/UpdateInvoice", formData)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            this.$router.push("/invoices");
            this.$toasted.success("Invoice updated successfully", {
              duration: 5000
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toasted.error(
            "Invalid inputs or check and fill all required inputs",
            { duration: 5000 }
          );
        });
    }
  },

  // computed: {
  //   optionSelected() {
      
  //   }
  // },
  mounted() {
    this.getAllInvoices();
    return this.value.map((v) => v.fullName);
  }
};
</script>

<style scoped>
.iconify {
  color: red;
}

.btn-primary {
  background: #0c4369 !important;
  border: none;
}
</style>
